@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

body {
    font-family: 'Poppins', sans-serif !important;
    background-color: rgb(133, 170, 228);
    color: rgb(37, 37, 37);
}

.bg-section {
    background-color: rgb(197, 210, 230);
}

@media (min-width: 1400px) {
    .container {
        max-width: 1140px;
    }
}